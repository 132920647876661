.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div {

.container {
  button {

  }
}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


// Ant Desgin Design Override

textarea + .ant-input-suffix {
  font-size: 13px;
}

.ant-upload-list {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  
  .ant-upload-list-item-container {
    /* 
    padding: 12px !important;
    height: 32px !important;
    font-size: 13px !important; */
    width: 46%;
    // margin: 0 8px;
    margin: 4px 16px 0;
    justify-items: flex-start;
    justify-content: flex-start;

    .custom-item-renderer {
      width: 100%;
      height: 85px;
      display: inline-flex;
      flex-direction: column;
      border: 1px #dadada solid;
      border-radius: 8px;
      margin: 10px 0 0 0;
      padding: 6px 12px;

      .flex-wrap {
        overflow: hidden;
        white-space: nowrap;
        width: 425px;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
      }

      .file-icon {
        display: inline;
        width: 30px;
        font-size: 26px;
        color: #777;
        margin-right: 8px;
      }
      
      .button-group {
        margin-top: auto;
        justify-content: right;
        display: flex;
      }
    }
  }
}