
// App
// ----------------------------------------------

.appName {
  font-weight: 600;
  font-size: 19px;
}

.avatar {
  margin-top: 19px;
  // font-weight: 500;
  // line-height: 34px !important;
}

// Project Task Column
// ----------------------------------------------

.taskColumn {
  background-color: #edf2f7;
  border-radius: 10px;
  padding: 4px 12px 6px 12px;
  margin: 0;
}

.taskRowContainer {
  display: flex;
  justify-content: left;
  // transition: 0.4s ease;
}
.taskRow {
  cursor: default; // grab
  padding: 8px 12px;
  border-radius: 6px;
  margin: 4px 0px;
  background-color: #fefefe;
  // box-sizing: border-box;
  display: flex;

  // width: 100%;
  width: 100%;
  
  // -moz-transition: 0.25s ease; 
  // -webkit-transition: 0.25s ease; 
  // transition: 0.15s ease;
}
.taskRow:hover {
  width: 100%;
  // transition: 0.15s ease;
}
.taskRow:active {
  cursor: grabbing;
  box-shadow: 2px 2px 8px rgba(0,0,0,0.15);
  opacity: 0.9;
}

/*
.taskRow:focus-within {
  // background-color: #f7fbff;
  color: #999;
  font-weight: 600;
  opacity: 0.7;
}
.taskRow[aria-pressed="true"] {
  background-color: #fff;
  color: #000;
}*/

.taskRowContent {
  width: auto;
}
.taskRowDetail {
  // margin: 6px;
  // padding-left: 6px;
  right: 0;
  display: none;
  margin-left: -48px;
}
.taskRowContainer:hover .taskRowDetail {
  font-weight: 900;
  display: inline;
}

.taskCompleted {
  opacity: 0.9;
  color: #666;
  background-color: #fafafa;
}
.taskCompleteBox {
  margin: 2px 8px 0 0;
  font-size: 17px;
  height: 100%;
  display: inline-block;
}

// Project Task Edit
// ----------------------------------------------

.taskEditTitle textarea {
  font-weight: 500;
  // Parent has set as "allowClear" so span container made.
}
.taskEditNotes {
  color: #666;
  font-size: 14px;
  margin-top: 24px;
}
